import React from 'react';

const Notify = () => {
    return (
        <section>
            Notify
        </section>
    );
};

export default Notify;