import React from 'react';

const NotFound = () => {
    return (
        <section className="notFound">
            <h1>404 | NOT Found.</h1>
        </section>
    );
};

export default NotFound;